import { useContext, useState } from 'react';
import FormContainer from './form-container';
import { Context } from '../store/user';
import CookieService from '../services/cookie-service';
import { updateUserData } from '../api/api';
import { validateIban, validateBic } from '../lib/validation';

const BankForm = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { userData } = state;
  const {
    bankName,
    accountName,
    accountNumber,
    sortCode,
    iban,
    swiftBic,
  } = userData;

  const fields = [
    {
      id: 'bank-form-bank-name',
      type: 'text',
      required: 'true',
      label: 'Bank name',
      placeholder: 'Natwest',
      helpText: 'Please add your bank\'s name in this field.',
      value: bankName,
    },
    {
      id: 'bank-form-account-name',
      type: 'text',
      required: 'true',
      label: 'Account name',
      placeholder: 'J Smith',
      helpText: 'Please add your account name in this field.',
      value: accountName,
    },
    {
      id: 'bank-form-account-number',
      type: 'number',
      required: 'true',
      label: 'Account number',
      placeholder: '12345678',
      helpText: 'Please add a valid account number in this field.',
      value: accountNumber,
      validationMethod: (input) => /^[0-9]{8}$/.test(input),
    },
    {
      id: 'bank-form-sort-code',
      type: 'text',
      required: 'true',
      label: 'Sort code',
      placeholder: '12-34-56',
      helpText: 'Please add a valid sort code in this field.',
      value: sortCode,
      validationMethod: (input) => /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(input),
    },
    {
      id: 'bank-form-iban',
      type: 'text',
      required: 'true',
      label: 'IBAN',
      placeholder: 'GB29NWBK60161331926819',
      helpText: 'Please add a valid IBAN in this field.',
      value: iban,
      validationMethod: (input) => validateIban(input),
    },
    {
      id: 'bank-form-swift-bic',
      type: 'text',
      required: 'true',
      label: 'Swift/BIC',
      placeholder: 'NWBKGB2L',
      helpText: 'Please add a valid Swift/BIC in this field.',
      value: swiftBic,
      validationMethod: (input) => validateBic(input),
    },
  ];

  const formSubmitCallback = async (formFields) => {
    const AUTH_COOKIE = 'p1_jwt';
    setShowError(false);
    setShowSuccess(false);
    const token = CookieService.getCookie(AUTH_COOKIE);

    if (token) {
      const updatedUserData = await updateUserData(token, formFields);

      if (updatedUserData) {
        // Set user in global context.
        dispatch({
          type: 'UPDATE_USER',
          payload: updatedUserData,
        });
        setShowSuccess(true);
      } else {
        // something went wrong
        setShowError(true);
      }
    } else {
      // something went wrong
      setShowError(true);
    }
  };

  return (
    <FormContainer
      fields={fields}
      onSubmitCallback={formSubmitCallback}
      submitButtonText="Update your bank details"
      errorMessage="Sorry we couldn't update your address. Please check your details and try again."
      showErrorMessage={showError}
      successMessage="Your bank details have been updated."
      showSuccessMessage={showSuccess}
    />
  );
};

export default BankForm;
