class CookieService {
  static setCookie = (cname, cvalue, exdays, domain = '') => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    if (!domain) {
      document.cookie = `${cname}=${cvalue}; ${expires}; path=/`;
    } else {
      document.cookie = `${cname}=${cvalue}; ${expires}; path=/; domain=.${domain}`;
    }
  };

  static updateCookie = (cname, cvalue) => {
    const currentValue = this.checkCookie();

    if (currentValue) {
      this.setCookie(cname, cvalue, 30);
    }
  };

  static removeCookie = (cname, domain) => {
    if (!domain) {
      document.cookie = `${cname}=;max-age=0;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } else {
      document.cookie = `${cname}=;max-age=0;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain}`;
    }
  };

  static getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  static checkCookie = (cname) => {
    const cvalue = this.getCookie(cname);
    if (cvalue !== '') {
      return cvalue;
    }
    return false;
  };
}

export default CookieService;
