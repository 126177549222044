import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from '../services/auth-service';
import { Context } from '../store/user';
import CookieService from '../services/cookie-service';

const Logout = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);

  const {
    userData,
  } = state;

  const {
    cookieHash,
  } = userData;

  CookieService.removeCookie(`wordpress_logged_in_${cookieHash}`, process.env.REACT_APP_WP_API_COOKIE_DOMAIN);
  CookieService.removeCookie(`wordpress_sec_${cookieHash}`, process.env.REACT_APP_WP_API_COOKIE_DOMAIN);

  AuthService.signOut();

  dispatch({
    type: 'LOGOUT',
  });

  return <Redirect to="/login" />;
};

export default Logout;
