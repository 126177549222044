import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AuthService from '../services/auth-service';
import FormContainer from './form-container';
import { Context } from '../store/user';
import { getUserData } from '../api/api';
import CookieService from '../services/cookie-service';

const LoginForm = withRouter(({ redirectPath, history }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const auth = new AuthService();
  const [fields, setFields] = useState([
    {
      id: 'login-form-email',
      type: 'email',
      required: 'true',
      label: 'Email',
      placeholder: 'e.g. alice.smith@company.com',
      helpText: 'Please enter a valid email address',
    },
    {
      id: 'login-form-password',
      type: 'password',
      required: 'true',
      label: 'Password',
      placeholder: 'Your password',
      helpText: 'Please enter a password',
    },
  ]);

  const [submitButtonText, setSubmitButtonText] = useState('log in');
  const [errorMessage, setErrorMessage] = useState("Sorry we couldn't log you in. Please check your details and try again.");
  const [successMessage, setSuccessMessage] = useState('');
  const formSubmitCallback = async (formValues) => {
    setShowError(false);
    setShowSuccess(false);
    const otp = formValues[fields?.[2]?.id] || '';
    const signedIn = await auth.signIn(formValues[fields[0].id], formValues[fields[1].id], otp);
    if (signedIn?.statusCode === 200 && signedIn?.success) {
      // Use token to fetch userData
      if (signedIn.data && signedIn.data.token) {
        const userData = await getUserData(signedIn.data.token);

        // Set WP login cookies
        CookieService.setCookie(`wordpress_logged_in_${signedIn.data.hash}`, signedIn.data.loggedInCookie, 1, process.env.REACT_APP_WP_API_COOKIE_DOMAIN);
        CookieService.setCookie(`wordpress_sec_${signedIn.data.hash}`, signedIn.data.secCookie, 1, process.env.REACT_APP_WP_API_COOKIE_DOMAIN);

        // Set user in global context
        dispatch({
          type: 'LOGIN',
          payload: {
            ...userData,
            cookieHash: signedIn.data.hash,
          },
        });

        history.push(redirectPath);
      } else {
        // something went wrong
        setShowError(true);
      }
    } else if (signedIn?.code === 'email_sent') {
      if (fields.length === 2) {
        setFields((prev) => [...prev,
          {
            id: 'login-form-otp',
            type: 'text',
            required: 'true',
            label: 'OTP',
            placeholder: 'OTP',
            helpText: 'Please enter a OTP',
          },
        ]);
        setSubmitButtonText('Verify OTP');
        document.getElementById('login-form-email').readOnly = true;
        document.getElementById('login-form-password').readOnly = true;
        setSuccessMessage(signedIn?.message);
        setShowSuccess(true);
      }
    } else if (signedIn?.code === 'otp_expiry') {
      setErrorMessage(signedIn?.message);
      setShowError(true);
    } else {
      // something went wrong
      setShowError(true);
    }
  };

  return (
    <FormContainer
      fields={fields}
      onSubmitCallback={formSubmitCallback}
      submitButtonText={submitButtonText}
      errorMessage={errorMessage}
      showErrorMessage={showError}
      successMessage={successMessage}
      showSuccessMessage={showSuccess}
    />
  );
});

LoginForm.propTypes = {
  redirectPath: PropTypes.string,
};

LoginForm.defaultPropts = {
  redirectPath: '/',
};

export default LoginForm;
