import { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import PrivateRoute from './components/private-route';
import Header from './components/header';
import Login from './pages/login';
import Logout from './components/logout';
import Loader from './components/loader';
import Account from './pages/account';
import Documents from './pages/documents';
import Investments from './pages/investments';
import Details from './pages/details';
import { Context } from './store/user';
import './app.scss';
import CookieService from './services/cookie-service';
import { getUserData } from './api/api';

function App() {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const AUTH_COOKIE = 'p1_jwt';

  useEffect(async () => {
    // Get token from cookie.
    const token = CookieService.getCookie(AUTH_COOKIE);

    if (token) {
      setLoading(true);

      const userData = await getUserData(token);

      if (userData) {
        // Set user in global context.
        dispatch({
          type: 'LOGIN',
          payload: userData,
        });
      }

      setLoading(false);
    }
  }, []);

  return (
    <BrowserRouter basename={`${process.env.REACT_APP_ROUTER_BASENAME}` || '/'}>
      <div className="app">
        <Header />
        { loading ? (
          <Loader />
        ) : (
          <>
            <Switch>
              <Route path="/" exact>
                <Login />
              </Route>
              <Route path="/login" exact>
                <Login />
              </Route>
              <Route path="/logout" exact>
                <Logout />
              </Route>
              <PrivateRoute path="/account" component={Account} exact />
              <PrivateRoute path="/account/investments" component={Investments} exact />
              <PrivateRoute path="/account/documents" component={Documents} exact />
              <PrivateRoute path="/account/details" component={Details} exact />
            </Switch>
          </>
        ) }
      </div>
    </BrowserRouter>
  );
}

export default App;
