import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../services/auth-service';

const PrivateRoute = ({
  component: Component, path, exact, location,
}) => (
  <Route
    path={path}
    exact={exact}
    render={(props) => (
      AuthService.isAuthenticated() === true
        // eslint-disable-next-line react/jsx-props-no-spreading
        ? <Component {...props} />
        : (
          <Redirect to={{
            pathname: '/login',
            state: { from: location },
          }}
          />
        )
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  path: PropTypes.string,
  exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  component: null,
  location: {},
  path: '/',
  exact: false,
};

export default PrivateRoute;
