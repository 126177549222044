import PropTypes from 'prop-types';

const PageTitle = (props) => {
  const { title, asideTitle, underTitle } = props;

  if (!title) return null;

  return (
    <div className="hero is-light page-title">
      <div className="hero-body">
        <div className="container">
          { asideTitle ? (
            <h1 className="title mb-1 is-size-2">
              { title }
              <span className="aside-title">
                {' '}
                {asideTitle}
              </span>
            </h1>
          ) : (
            <h1 className="title is-size-2 mb-1">{ title }</h1>
          )}
          { underTitle && <span className="under-title">{ underTitle }</span> }
        </div>
      </div>
    </div>
  );
};

PageTitle.defaultProps = {
  asideTitle: '',
  underTitle: '',
};

PageTitle.propTypes = {
  asideTitle: PropTypes.string,
  underTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default PageTitle;
