import { useState, useContext } from 'react';
import { Context } from '../store/user';
import ForgottenPasswordForm from './forgotten-password-form';
import AuthService from '../services/auth-service';

const AccountPasswordReset = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [showResetForm, setShowResetForm] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = state;
  const { emailAddress } = userData;
  const auth = new AuthService();

  const reset = () => {
    setShowResetForm(false);
    setIsLoading(false);
    setShowErrorMessage(false);
  };

  const handleResetPasswordClick = async () => {
    setShowErrorMessage(false);

    // can't send a request without an email address
    if (!emailAddress) {
      return;
    }

    setIsLoading(true);

    const resetResponse = await auth.resetPassword(emailAddress);

    if (resetResponse) {
      setShowResetForm(true);
    } else {
      // an error occurred on password fetch call
      setShowErrorMessage(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      {
        !showResetForm ? (
          <>
            <div className="buttons">
              <button
                type="button"
                className={`button is-primary ${isLoading ? 'is-loading' : ''}`}
                onClick={handleResetPasswordClick}
              >
                Reset password
              </button>
            </div>
            {
              showErrorMessage && (
                <div className="notification is-danger">
                  {/* eslint-disable-next-line max-len */}
                  Sorry, something went wrong resetting your password. Please try again.
                </div>
              )
            }
          </>
        ) : (
          <ForgottenPasswordForm
            startingStage="set new password"
            startingEmail={emailAddress}
            successMessageVisible
          />
        )
      }
      {
        showResetForm && (
        <button type="button" className="button is-link" onClick={reset}>
          cancel
        </button>
        )
      }
    </>
  );
};

export default AccountPasswordReset;
