import { useContext, useState } from 'react';
import {
  NavLink as Link,
} from 'react-router-dom';
import classNames from 'classnames';
import logo from '../images/logo.png';
import './header.scss';
import { Context } from '../store/user';

const Header = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const { isLoggedIn } = state;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleBurgerToggle = () => setMobileMenuOpen(!mobileMenuOpen);
  const navbarBurgerClassName = classNames('navbar-burger', { 'is-active': mobileMenuOpen });
  const navbarMenuClassName = classNames('navbar-menu', { 'is-active': mobileMenuOpen });

  return (
    <nav className="navbar is-dark header" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item logo" to="/">
            <img src={logo} alt="P1 Capital Dashboard" />
          </Link>
          { isLoggedIn && (
            <button onClick={handleBurgerToggle} type="button" className={navbarBurgerClassName} aria-label="menu" aria-expanded="false">
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          ) }
        </div>
        { isLoggedIn && (
          <div className="navbar-end">
            <div className={navbarMenuClassName}>
              <div className="navbar-start">
                <div className="navbar-item has-dropdown is-hoverable">
                  <Link to="/account" className="navbar-link" activeClassName="is-active">
                    Account
                  </Link>
                  <div className="navbar-dropdown is-boxed is-right">
                    <Link to="/account/investments" className="navbar-item" activeClassName="is-active">
                      My Investments
                    </Link>
                    <Link to="/account/documents" className="navbar-item" activeClassName="is-active">
                      My Documents
                    </Link>
                    <Link to="/account/details" className="navbar-item" activeClassName="is-active">
                      My Details
                    </Link>
                    <hr className="navbar-divider" />
                    <Link to="/logout" className="navbar-item">
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) }
      </div>
    </nav>
  );
};

export default Header;
