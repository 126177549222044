import { useState } from 'react';
import AuthService from '../services/auth-service';
import FormContainer from './form-container';

const resetStage = {
  RESET_PASSWORD: 'reset password',
  ENTER_NEW_PASSWORD: 'set new password',
  FINISHED: 'successful reset',
};

const fields = {
  [resetStage.RESET_PASSWORD]: [
    {
      id: 'forgotten-password-form-email',
      type: 'email',
      required: 'true',
      label: 'Email',
      placeholder: 'e.g. alice.smith@company.com',
      helpText: 'Please enter a valid email address',
    },
  ],
  [resetStage.ENTER_NEW_PASSWORD]: [
    {
      id: 'forgotten-password-form-code',
      type: 'text',
      required: 'true',
      label: 'Code',
      placeholder: '',
      helpText: 'Please enter a reset code',
    },
    {
      id: 'forgotten-password-form-new-password',
      type: 'password',
      required: 'true',
      label: 'New password',
      placeholder: '',
      helpText: 'Please enter a new password',
    },
  ],
};

const ForgottenPasswordForm = ({
  startingStage = resetStage.RESET_PASSWORD,
  startingEmail = '',
  successMessageVisible = false,
}) => {
  const [stage, setStage] = useState(startingStage);
  const [userEmail, setUserEmail] = useState(startingEmail);
  const [showSuccessMessage, setShowSuccessMessage] = useState(successMessageVisible);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const auth = new AuthService();

  const resetFormCallback = async (formValues) => {
    setUserEmail('');
    setShowErrorMessage(false);
    setShowSuccessMessage(false);

    const email = startingEmail || formValues[fields[resetStage.RESET_PASSWORD][0].id];
    const resetResponse = await auth.resetPassword(email);

    if (resetResponse) {
      setShowSuccessMessage(true);
      setStage(resetStage.ENTER_NEW_PASSWORD);
    } else {
      // an error occurred on password fetch call
      setUserEmail('');
      setShowErrorMessage(true);
    }

    setUserEmail(email);
  };

  const newPasswordFormCallback = async (formValues) => {
    setShowErrorMessage(false);
    setShowSuccessMessage(false);

    const code = formValues[fields[resetStage.ENTER_NEW_PASSWORD][0].id];
    const password = formValues[fields[resetStage.ENTER_NEW_PASSWORD][1].id];
    const resetResponse = await auth.setNewPassword(userEmail, code, password);

    if (resetResponse) {
      setStage(resetStage.FINISHED);
    } else {
      // an error occurred on password fetch call
      setShowErrorMessage(true);
    }
  };

  const displayForm = () => {
    switch (stage) {
      case resetStage.RESET_PASSWORD:
        return (
          <FormContainer
            fields={fields[resetStage.RESET_PASSWORD]}
            onSubmitCallback={resetFormCallback}
            submitButtonText="reset password"
            showErrorMessage={showErrorMessage}
            errorMessage="Sorry, password reset request failed. Check your details and try again."
          />
        );
      case resetStage.ENTER_NEW_PASSWORD:
        return (
          <FormContainer
            fields={fields[resetStage.ENTER_NEW_PASSWORD]}
            onSubmitCallback={newPasswordFormCallback}
            submitButtonText="set new password"
            showSuccessMessage={showSuccessMessage}
            successMessage="Check the email address registered with this account for your password recovery code."
            showErrorMessage={showErrorMessage}
            errorMessage="Something went wrong setting a new password. Check your code and try again."
          />
        );
      case resetStage.FINISHED:
        return (
          <div className="notification is-success">
            Your password has been successfully reset.
          </div>
        );
      default:
        return (
          <></>
        );
    }
  };

  return displayForm();
};

export default ForgottenPasswordForm;
