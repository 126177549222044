import { useContext } from 'react';
import {
  NavLink as Link,
} from 'react-router-dom';
import PageTitle from '../components/page-title';
import Page from '../components/page';
import { Context } from '../store/user';
import Breadcrumbs from '../components/breadcrumbs';

const cards = [
  {
    title: 'Investments',
    slug: '/account/investments',
  },
  {
    title: 'Documents',
    slug: '/account/documents',
  },
  {
    title: 'Details',
    slug: '/account/details',
  },
];

const breadcrumbs = [
  {
    to: '/account',
    label: 'Account',
  },
];

const Account = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const { userData } = state;
  const { investorName, lastLogin } = userData;
  const lastLoginString = lastLogin ? `Last login: ${lastLogin}` : '';

  return (
    <>
      <PageTitle
        title="Welcome"
        asideTitle={investorName}
        underTitle={lastLoginString}
      />
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="columns">
          { cards.map((card) => {
            const { title, slug } = card;

            return (
              <div className="column" key={title}>
                <Link to={slug} className="box">
                  <h3 className="is-size-3 has-text-weight-bold">
                    <span className="has-text-weight-normal">My</span>
                    <br />
                    { title }
                  </h3>
                </Link>
              </div>
            );
          }) }
        </div>
      </Page>
    </>
  );
};

export default Account;
