import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../components/page';
import LoginForm from '../components/login-form';
import ForgottenPasswordForm from '../components/forgotten-password-form';
import AuthService from '../services/auth-service';

const Login = ({ location }) => {
  const [forgottenPasswordFormShown, setForgottenPasswordFormShown] = useState(false);
  const from = (location && location.state && location.state.from) || '/account';

  return (
    <>
      {
        AuthService.isAuthenticated()
          ? <Redirect to="/account" />
          : (
            <Page>
              { forgottenPasswordFormShown ? (
                <>
                  <h1 className="title is-2">Forgotten Password</h1>
                  <ForgottenPasswordForm />
                  <hr />
                  <div className="notification content">
                    <button type="button" className="button is-dark" onClick={() => setForgottenPasswordFormShown(false)}>Take me back to the log-in form</button>
                  </div>
                </>
              ) : (
                <>
                  <h1 className="title is-2">Sign In</h1>
                  <LoginForm redirectPath={from} />
                  <hr />
                  <div className="notification content">
                    <h3>Problems signing in?</h3>
                    <button type="button" className="button is-dark" onClick={() => setForgottenPasswordFormShown(true)}>I&apos;ve forgotten my password</button>
                  </div>
                </>
              ) }
            </Page>
          )
      }
    </>
  );
};

Login.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

Login.defaultProps = {
  location: {},
};

export default Login;
