import {
  NavLink as Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ breadcrumbs }) => {
  if (!breadcrumbs || breadcrumbs.length < 0) return null;

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        { breadcrumbs.map((breadcrumb, index) => {
          const isLast = breadcrumbs.length === index + 1;
          const { to, label } = breadcrumb;

          if (!to || !label) return null;

          return (
            <li className={isLast ? 'is-active' : ''} key={label}>
              <Link to={to}>
                { label }
              </Link>
            </li>
          );
        }) }
      </ul>
    </nav>
  );
};
Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Breadcrumbs;
