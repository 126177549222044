const wpDomain = process.env.REACT_APP_WP_API_DOMAIN;
const protocol = process.env.REACT_APP_PROTOCOL;
const wpAuthAPIEndpoint = process.env.REACT_APP_WP_AUTH_API_ENDPOINT;
const wpPasswordResetEndpoint = process.env.REACT_APP_WP_RESET_PASSWORD_ENDPOINT;

export const baseEndpoint = `${protocol}://${wpDomain}/wp-json/${wpAuthAPIEndpoint}`;
const userDataEndpoint = `${baseEndpoint}/user-data`;
export const passwordResetEndpoint = `${protocol}://${wpDomain}/wp-json/${wpPasswordResetEndpoint}`;

export default userDataEndpoint;
