import PropTypes from 'prop-types';
import './documents.scss';

const Documents = ({ documents }) => {
  if (!documents || documents.length < 0) return null;

  return (
    <ul className="documents">
      { documents.map((document) => {
        const { title, file } = document;

        if (!title || !file) return null;

        const { url, icon } = file;

        if (!url) return null;

        return (
          <li key={title}>
            <a href={url} target="_blank" rel="noreferrer" download>
              {title}
              { icon && <img src={icon} alt={title} /> }
            </a>
          </li>
        );
      }) }
    </ul>
  );
};

Documents.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Documents;
