import PropTypes from 'prop-types';

const Page = ({ children }) => (
  <div className="section page">
    <div className="container">
      { children }
    </div>
  </div>
);

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Page;
