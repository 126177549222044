import { useContext, useState } from 'react';
import FormContainer from './form-container';
import { Context } from '../store/user';
import { updateUserData } from '../api/api';
import CookieService from '../services/cookie-service';

const detailsForm = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { userData } = state;
  const {
    addressLine1,
    addressLine2,
    city,
    country,
    postCode,
    emailAddress,
    phoneNumber,
    dateOfBirth,
  } = userData;

  const fields = [
    {
      id: 'details-form-address-line-1',
      type: 'text',
      required: 'true',
      label: 'Address line 1',
      placeholder: '123 Smith Street',
      helpText: 'Please add line 1 of your address in this field.',
      value: addressLine1,
    },
    {
      id: 'details-form-address-line-2',
      type: 'text',
      required: 'false',
      label: 'Address line 2',
      placeholder: '',
      helpText: '',
      value: addressLine2,
    },
    {
      id: 'details-form-city',
      type: 'text',
      required: 'true',
      label: 'City',
      placeholder: 'Leeds',
      helpText: 'Please add your city.',
      value: city,
    },
    {
      id: 'details-form-country',
      type: 'text',
      required: 'true',
      label: 'Country',
      placeholder: 'United Kingdom',
      helpText: 'Please add your country.',
      value: country,
    },
    {
      id: 'details-form-zip-post-code',
      type: 'text',
      required: 'true',
      label: 'Zip / Post code',
      placeholder: 'LS21 1BW',
      helpText: 'Please add your zip or post code.',
      value: postCode,
    },
    {
      id: 'details-form-email',
      type: 'email',
      required: 'true',
      label: 'Email address',
      placeholder: 'alice.smith@company.com',
      helpText: 'Please enter a valid email address',
      value: emailAddress,
    },
    {
      id: 'details-form-phone-number',
      type: 'number',
      required: 'true',
      label: 'Contact number',
      placeholder: '07123 121212',
      helpText: 'Please add your phone number.',
      value: phoneNumber,
    },
    {
      id: 'details-form-date-of-birth',
      type: 'date',
      required: 'true',
      label: 'Date of birth',
      placeholder: '01/01/1975',
      helpText: 'Please add your date of birth.',
      value: dateOfBirth,
    },
  ];

  const formSubmitCallback = async (formFields) => {
    const AUTH_COOKIE = 'p1_jwt';
    setShowError(false);
    setShowSuccess(false);
    const token = CookieService.getCookie(AUTH_COOKIE);

    if (token) {
      const updatedUserData = await updateUserData(token, formFields);

      if (updatedUserData) {
        // Set user in global context.
        dispatch({
          type: 'UPDATE_USER',
          payload: updatedUserData,
        });
        setShowSuccess(true);
      } else {
        // something went wrong
        setShowError(true);
      }
    } else {
      // something went wrong
      setShowError(true);
    }
  };

  return (
    <FormContainer
      fields={fields}
      onSubmitCallback={formSubmitCallback}
      submitButtonText="Update your personal details"
      errorMessage="Sorry we couldn't update your address. Please check your details and try again."
      showErrorMessage={showError}
      successMessage="Your personal details have been updated."
      showSuccessMessage={showSuccess}
    />
  );
};

export default detailsForm;
