import { isValidIBAN, isValidBIC } from 'ibantools';

const validateIban = (iban) => {
  if (!iban) return false;

  return isValidIBAN(iban);
};

const validateBic = (bic) => {
  if (!bic) return false;

  return isValidBIC(bic);
};

export { validateIban, validateBic };
