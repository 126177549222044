import { useContext, useState } from 'react';
import PageTitle from '../components/page-title';
import Page from '../components/page';
import Breadcrumbs from '../components/breadcrumbs';
import { Context } from '../store/user';
import { dateFormat, currencyFormatWithoutCurrencySymbol } from '../lib/number';
import '../components/investments-table.scss';
import checkmark from '../images/check-solid.svg';

const breadcrumbs = [
  {
    to: '/account',
    label: 'Account',
  },
  {
    to: '/investments',
    label: 'Investments',
  },
];

const Investments = () => {
  const [state] = useContext(Context);
  const { userData } = state;
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const {
    // totalInvested,
    // totalAccruedInterest,
    // totalInvestmentValue,
    totalInvestmentAmountData,
    totalInvestmentValueData,
    investmentAccruedInterestData,
    investments,
  } = userData;

  return (
    <>
      <PageTitle title="My Investments" />
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h2 className="title is-size-2 mb-6">Portfolio</h2>
        <div className="tab-header">
          <button type="button" onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'tab active' : 'tab'}>
            Active
          </button>
          <button type="button" onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'tab active' : 'tab'}>
            Matured
          </button>
        </div>
        {!investments || investments.length < 1 ? (
          <p>You currently have no investments.</p>
        ) : (
          <>
            <div className="columns">
              <div className={activeTab === 1 ? 'column show' : 'column hide'}>
                {totalInvestmentAmountData?.length >= 1 && (
                  <div>
                    <p className="heading">Total Invested</p>
                    {totalInvestmentAmountData.map((investment) => {
                      const { amount, currency } = investment;
                      return (
                        <p className="title" key={`${currency}-investment`}>
                          {currency || ''}
                          {currencyFormatWithoutCurrencySymbol(amount)}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={activeTab === 1 ? 'column show' : 'column hide'}>
                {investmentAccruedInterestData?.length >= 1 && (
                  <div>
                    <p className="heading">Total Accrued Interest</p>
                    {investmentAccruedInterestData.map((investment) => {
                      const { amount, currency } = investment;
                      return (
                        <p className="title" key={`${currency}-interest`}>
                          {currency || ''}
                          {currencyFormatWithoutCurrencySymbol(amount)}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={activeTab === 1 ? 'column show' : 'column hide'}>
                {totalInvestmentValueData?.length >= 1 && (
                <div>
                  <p className="heading">Total Investment Value</p>
                  {totalInvestmentValueData.map((investment) => {
                    const { amount, currency } = investment;
                    return (
                      <p className="title" key={`${currency}-value`}>
                        {currency || ''}
                        {currencyFormatWithoutCurrencySymbol(amount)}
                      </p>
                    );
                  })}
                </div>
                )}
              </div>
            </div>
            <hr />
            <table className={activeTab === 1 ? 'table investments-table show' : 'table investments-table hide'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date Invested</th>
                  <th>Date Of Maturity</th>
                  <th width="150px">Amount</th>
                  <th>Interest Rate</th>
                  <th width="150px">Accrued Interest</th>
                  <th width="150px">Total Value</th>
                  <th>Funds Repaid</th>
                </tr>
              </thead>
              <tbody>
                {investments.map((investment) => {
                  // eslint-disable-next-line max-len
                  const {
                    investment_date: dateInvested,
                    investment_date_of_maturity_group: dateOfMaturityGroup,
                    investment_amount: amount,
                    investment_accrued_interest: accruedInterest,
                    investment_total_value: totalValue,
                    investment_bond: investmentBond, funds_repaid: fundsRepaid,
                  } = investment;

                  // eslint-disable-next-line max-len
                  const { post_title: name, interest_rate: interestRate, invest_currency: currency } = investmentBond;

                  // eslint-disable-next-line max-len
                  const { investment_date_of_maturity: dateOfMaturity, override_investment_date_of_maturity: overrideDateOfMaturity, overridden_investment_date_of_maturity: overriddenDateOfMaturity } = dateOfMaturityGroup;
                  if (!fundsRepaid) {
                    return (
                      <tr key={`${name}-${dateInvested}`}>
                        <th>{name}</th>
                        <td>{dateFormat(dateInvested)}</td>
                        <td>
                          {overrideDateOfMaturity
                            ? dateFormat(overriddenDateOfMaturity)
                            : dateFormat(dateOfMaturity) }
                        </td>
                        <td>
                          {currency}
                          {currencyFormatWithoutCurrencySymbol(amount)}
                        </td>
                        <td>{`${interestRate}%`}</td>
                        <td>
                          {currency}
                          {currencyFormatWithoutCurrencySymbol(accruedInterest)}
                        </td>
                        <td>
                          <strong>
                            {currency}
                            {currencyFormatWithoutCurrencySymbol(totalValue)}
                          </strong>
                        </td>
                        <td>{fundsRepaid && <img style={{ width: 18, marginTop: 3 }} src={checkmark} alt="Funds repaid" />}</td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
            <table className={activeTab === 2 ? 'table investments-table show' : 'table investments-table hide'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date Invested</th>
                  <th>Date Of Maturity</th>
                  <th width="150px">Amount</th>
                  <th>Interest Rate</th>
                  <th width="150px">Accrued Interest</th>
                  <th width="150px">Total Value</th>
                  <th>Funds Repaid</th>
                </tr>
              </thead>
              <tbody>
                {investments.map((investment) => {
                  const {
                    investment_date: dateInvested,
                    investment_date_of_maturity_group: dateOfMaturityGroup,
                    investment_amount: amount,
                    investment_accrued_interest: accruedInterest,
                    investment_total_value: totalValue,
                    investment_bond: investmentBond, funds_repaid: fundsRepaid,
                  } = investment;

                  const {
                    post_title: name, interest_rate: interestRate,
                    invest_currency: currency,
                  } = investmentBond;

                  const {
                    investment_date_of_maturity: dateOfMaturity,
                    override_investment_date_of_maturity: overrideDateOfMaturity,
                    overridden_investment_date_of_maturity: overriddenDateOfMaturity,
                  } = dateOfMaturityGroup;
                  if (fundsRepaid) {
                    return (
                      <tr key={`${name}-${dateInvested}`}>
                        <th>{name}</th>
                        <td>{dateFormat(dateInvested)}</td>
                        <td>
                          {overrideDateOfMaturity
                            ? dateFormat(overriddenDateOfMaturity)
                            : dateFormat(dateOfMaturity)}
                        </td>
                        <td>
                          {currency}
                          {currencyFormatWithoutCurrencySymbol(amount)}
                        </td>
                        <td>{`${interestRate}%`}</td>
                        <td>
                          {currency}
                          {currencyFormatWithoutCurrencySymbol(accruedInterest)}
                        </td>
                        <td>
                          <strong>
                            {currency}
                            {currencyFormatWithoutCurrencySymbol(totalValue)}
                          </strong>
                        </td>
                        <td>{fundsRepaid && <img style={{ width: 18, marginTop: 3 }} src={checkmark} alt="Funds repaid" />}</td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
            <div className="block">
              <small style={{ color: '#aaa' }}>The accrued interest outlined above is for guidance purposes only, the actual amount payable on your investment will be confirmed to you in your quarterly or final reconciliation statement.</small>
            </div>
          </>
        )}
      </Page>
    </>
  );
};

export default Investments;
