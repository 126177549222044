import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';

const FormContainer = ({
  fields,
  onSubmitCallback,
  submitButtonText,
  successMessage,
  showSuccessMessage,
  showErrorMessage,
  errorMessage,
}) => {
  const [formValues, setFormValues] = useState({});
  const [touchedInputs, setTouchedInputs] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const isInputValid = (inputId) => (
    touchedInputs.includes(inputId) ? !formErrors.includes(inputId) : true
  );

  const handleOnChange = (e) => {
    if (!touchedInputs.includes(e.target.id)) {
      setTouchedInputs([
        ...touchedInputs,
        e.target.id,
      ]);
    }

    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // no submission if there are still errors
    if (formErrors.length > 0) {
      return;
    }

    setFormSubmitting(true);

    if (onSubmitCallback) {
      await onSubmitCallback(formValues);
      setFormSubmitting(false);
      return;
    }

    setFormSubmitting(false);
  };

  useEffect(() => {
    const errors = [];
    Object.entries(formValues).forEach(([key, value]) => {
      const inputField = filter(fields, (field) => field.id === key)[0];

      if (!inputField) { return; }

      const { validationMethod } = inputField;

      // If nothing entered.
      if (value === '') {
        errors.push(key);
      }

      // If value has validation method, test it.
      if (validationMethod && !validationMethod(value)) {
        errors.push(key);
      }
    });

    setFormErrors(errors);
  }, [formValues]);

  useEffect(() => {
    const nextFormValues = {};

    fields.forEach((field) => {
      if (field.value) {
        nextFormValues[field.id] = field.value;
      }
    });

    setFormValues({
      ...nextFormValues,
      ...formValues,
    });
  }, [fields]);

  return (
    <form className="box" onSubmit={handleFormSubmit}>
      {
        fields.map((field) => (
          <div className="field" key={field.id}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={field.id} className="label">
              {field.label}
            </label>
            <div className="control">
              <input
                id={field.id}
                className={`input ${!isInputValid(field.id) ? 'is-danger' : ''}`}
                type={field.type}
                placeholder={field.placeholder}
                onChange={handleOnChange}
                required={(field.required === 'true')}
                value={formValues[field.id] || ''}
              />
            </div>
            {
              !isInputValid(field.id) && <p className="help is-danger">{ field.helpText }</p>
            }
          </div>
        ))
      }
      <div className="field is-grouped">
        <div className="control">
          <button
            type="submit"
            className={`button is-link ${formSubmitting ? 'is-loading' : ''}`}
            disabled={formErrors.length > 0 || formSubmitting}
          >
            { submitButtonText }
          </button>
        </div>
      </div>
      {
        showSuccessMessage && successMessage && (
          <div className="notification is-success">
            { successMessage }
          </div>
        )
      }
      {
        showErrorMessage && errorMessage && (
          <div className="notification is-danger">
            { errorMessage }
          </div>
        )
      }
    </form>
  );
};

export default FormContainer;

FormContainer.defaultProps = {
  fields: [],
  onSubmitCallback: null,
  submitButtonText: 'submit',
  successMessage: '',
  showSuccessMessage: false,
  errorMessage: '',
  showErrorMessage: false,
};

FormContainer.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
  })),
  onSubmitCallback: PropTypes.func,
  submitButtonText: PropTypes.string,
  successMessage: PropTypes.string,
  showSuccessMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
  showErrorMessage: PropTypes.bool,
};
