import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import Reducer from '../reducers/user';

const initialState = {
  isLoggedIn: false,
  userData: {
    accountName: '',
    accountNumber: '',
    addressLine1: '',
    addressLine2: '',
    bankName: '',
    city: '',
    country: '',
    dateOfBirth: '',
    emailAddress: '',
    iban: '',
    investmentDocuments: '',
    investorCurrencySymbol: '',
    investments: '',
    investorName: '',
    kycAmlDocuments: '',
    lastLogin: '',
    messagesUpdatesDocuments: '',
    phoneNumber: '',
    postCode: '',
    sortCode: '',
    swiftBic: '',
  },
};

const Context = createContext(initialState);

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  );
};

export { Context };

Store.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Store;
