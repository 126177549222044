import PageTitle from '../components/page-title';
import Page from '../components/page';
import DetailsForm from '../components/details-form';
import BankForm from '../components/bank-form';
import AccountPasswordReset from '../components/account-password-reset';
import Breadcrumbs from '../components/breadcrumbs';

const breadcrumbs = [
  {
    to: '/account',
    label: 'Account',
  },
  {
    to: '/details',
    label: 'Details',
  },
];

const Details = () => (
  <>
    <PageTitle
      title="My Profile"
    />
    <Page>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block content">
        <h2>Your personal details</h2>
        <DetailsForm />
      </div>
      <hr />
      <div className="block content">
        <h2>Your bank details</h2>
        <BankForm />
      </div>
      <hr />
      <div className="block content">
        <h2>Update your password</h2>
        <AccountPasswordReset />
      </div>
    </Page>
  </>
);

export default Details;
