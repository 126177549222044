import { fetch } from '../api/api';
import CookieService from './cookie-service';
import { baseEndpoint as authUrl, passwordResetEndpoint as pwrUrl } from '../api/endpoints';

const AUTH_COOKIE = 'p1_jwt';

class AuthService {
  static isAuthenticated = () => {
    const token = CookieService.checkCookie(AUTH_COOKIE);
    return !!token;
  };

  static signOut = () => {
    CookieService.removeCookie(AUTH_COOKIE);
  };

  signIn = async (username, password, otp = '') => {
    if (!username || !password) return false;
    const params = {
      username,
      password,
    };
    if (otp !== '') {
      params.custom_auth = otp;
    }
    try {
      const response = await fetch(
        `${authUrl}/token`,
        'post',
        params,
      );

      if (response.data.success && response.data.statusCode === 200) {
        // stash the token in a cookie
        CookieService.setCookie(AUTH_COOKIE, response.data.data.token, 1);

        return response.data;
      }
      if (response.data?.code !== '') {
        return response.data;
      }

      // if we don't have a valid response and token then remove the cookie if it exists
      CookieService.removeCookie(AUTH_COOKIE);

      return false;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error signing in', err);
      return false;
    }
  };

  resetPassword = async (email) => {
    try {
      const response = await fetch(
        `${pwrUrl}/reset-password`,
        'post',
        {
          email,
        },
      );

      if (response.data && response.data.data && response.data.data.status === 200) {
        return true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error resetting password', err);
      return false;
    }

    return false;
  };

  setNewPassword = async (email, code, password) => {
    try {
      const response = await fetch(
        `${pwrUrl}/set-password`,
        'post',
        {
          email,
          code,
          password,
        },
      );

      if (response.data && response.data.data && response.data.data.status === 200) {
        return true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error setting a new password', err);
      return false;
    }

    return false;
  }
}

export default AuthService;
