import axios from 'axios';
import userDataEndpoint from './endpoints';

export const fetch = async (url, type = 'get', data = null, headers = null) => {
  const config = {
    method: type,
    url,
  };

  if (data && type !== 'get') {
    config.data = data;
  }

  if (headers) {
    config.headers = {
      ...headers,
    };
  }

  const response = await axios(config);

  return response;
};

export default fetch;

const getUserData = async (token) => {
  if (!token) return false;

  try {
    const response = await fetch(
      userDataEndpoint,
      'get',
      {},
      {
        Authorization: `Bearer ${token}`,
      },
    );

    if (response.status === 200) {
      return response.data;
    }

    return false;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error fetching user data', err);
    return false;
  }
};

const updateUserData = async (token, data) => {
  if (!token) return false;

  try {
    const response = await fetch(
      userDataEndpoint,
      'post',
      data,
      {
        Authorization: `Bearer ${token}`,
      },
    );

    if (response.status === 200) {
      return response.data;
    }

    return false;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error fetching user data', err);
    return false;
  }
};

export { getUserData, updateUserData };
